import React from 'react'
import PropTypes from 'prop-types'
import { Anchor, ActionAnchor } from '../common/Anchor'
import { Tooltip, Icon } from '../common/Ant'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'


const FlowMonitorSidebar = ({ flowId, flowPillarId, alertActive, handleCancel }) => {
  return (
		<span>
			{hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) ? (
				<span>
					<ActionAnchor onClick={handleCancel}>
						<Anchor
							to={`/inventory/flow-monitors/${flowId}`}
						>
							{flowPillarId}
						</Anchor>
					</ActionAnchor>
				</span>
			) : (
				<span>{flowPillarId}</span>
			)}
			{alertActive ? (
				<Tooltip title="Offline">
					<Icon type="poweroff" className="pl1 red f4" />
				</Tooltip>
			) : (
				<Tooltip title="Online">
					<Icon type="check-circle-o" className="pl1 green f4" />
				</Tooltip>
			)}
		</span>
  );
}

FlowMonitorSidebar.propTypes = {
	flowId: PropTypes.number.isRequired,
	flowPillarId: PropTypes.string.isRequired,
	handleCancel: PropTypes.func.isRequired,
	alertActive: PropTypes.bool.isRequired,
}

export default FlowMonitorSidebar;
