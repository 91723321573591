import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../services/api'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'

import { H3, H4 } from '../common/Headers'
import { Spin } from '../common/Ant'
import SelectField from '../common/formFields/SelectField'


const AssignedLeakPods = ({ flowPillarId }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [availableLeaks, setAvailableLeaks] = useState([])
    const [assignedLeaks, setAssignedLeaks] = useState([])
    const [showLeakForm, setShowLeakForm] = useState(false);
    const [selectedLeakPod, setSelectedLeakPod] = useState(undefined);
    const [selectedLeakPodIndex, setSelectedLeakPodIndex] = useState(undefined);

    // ================
    // API Functions
    // ================
    const getLeakPods = useCallback(() => {
        setIsLoading(true)

        api
        .getFlowMonitorAssignedLeakPods(flowPillarId)
        .then(data => {
            const available = ["---Select a Leak Pod---"]
            setAvailableLeaks(available.concat(data['available']))
            setAssignedLeaks(data['assigned'])
            setIsLoading(false)
        })
        .catch(e => {
            console.log("Error getting leak pods")
            console.log(e)
            setIsLoading(false)
        })
    }, [flowPillarId])

    const assignLeakPod = useCallback((leak_pillar_id) => {
        setIsLoading(true)

        api
        .assignLeakPodToFlowMonitor(flowPillarId, leak_pillar_id)
        .then(data => {
            setShowLeakForm(false)
            setSelectedLeakPod(undefined)
            setSelectedLeakPodIndex(undefined)
            getLeakPods()
        })
        .catch(e => {
            console.log("Error assigning leak pod")
            console.log(e)
            setIsLoading(false)
        })
    }, [flowPillarId, getLeakPods])

    const unassignLeakPod = useCallback((leak_pillar_id) => {
        setIsLoading(true)

        api
        .unassignLeakPodFromFlowMonitor(flowPillarId, leak_pillar_id)
        .then(data => {
            getLeakPods()
        })
        .catch(e => {
            console.log(e)
            setIsLoading(false)
        })
    }, [flowPillarId, getLeakPods])

    // Effects
    useEffect(() => {
        getLeakPods()
    }, [flowPillarId, getLeakPods])


    // ================
    // Modal Functions
    // ================
    const handleDropdownChange = (event) => {
        setSelectedLeakPodIndex(event.target.value);
        setSelectedLeakPod(availableLeaks[event.target.value]);
    };

    const toggleAssignLeakPod = () => {
        setShowLeakForm(!showLeakForm);
    };
    

    // ================
    // Render
    // ================
    return isLoading ? (
        <Spin size="large" className="w-100 center mv5" />
      ) : (
        <div>
        <div className="pa3">
            {/* ================= */}
            {/* Header */}
            {/* ================= */}
            <div className="flex justify-between items-center mb2">
                <H3>Assigned Leak Pods</H3>
                {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                    <button
                    type="button"
                    className="f7 link dim br2 ph3 pv2 dib white bg-dark-green bn"
                    onClick={toggleAssignLeakPod}
                >
                        {showLeakForm ? "Hide Menu" : "Assign Leak Pod"}
                    </button>
                ) : ""}
            </div>

            {/* ================= */}
            {/* Assign a Leak Pod */}
            {/* ================= */}
            {showLeakForm && (
                <div className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4">
                    <div className="flex justify-between items-center mb2">
                        <SelectField
                            options={availableLeaks.map(pod => pod['pillarId'])}
                            id="leakPodSelect"
                            name="leakPodSelect"
                            value={selectedLeakPodIndex}
                            onChange={handleDropdownChange}
                            label="Select a Leak Pod"
                        />
                        {selectedLeakPodIndex && selectedLeakPod["pillarId"] && (
                            <button
                                type="button"
                                className="f7 link dim br2 ph3 pv2 dib white bg-dark-blue bn" 
                                onClick={() => assignLeakPod(selectedLeakPod["pillarId"])}>
                                    Assign Leak Pod
                            </button>
                        )}
                    </div>
                    {selectedLeakPodIndex && selectedLeakPod["pillarId"] && (
                        <div>
                        <div className="flex items-start">
                            <H4 className="mr2 w-25">{selectedLeakPod["pillarId"]}</H4>
                            <H4 className="mr2 w-25">{selectedLeakPod["moduleId"]}</H4>
                            <H4 className="mr2 w-25">Activity: {selectedLeakPod["activity"]}</H4>
                            <H4 className="mr2 w-25">Status: {selectedLeakPod["status"]}</H4>
                        </div>
                        <div className="flex items-start">
                            <H4 className="mr2 w-25">Site: {selectedLeakPod["site"]}</H4>
                            <H4 className="mr2 w-25">Building: {selectedLeakPod["building"]}</H4>
                            <H4 className="mr2 w-25">Floor: {selectedLeakPod["floor"]}</H4>
                            <H4 className="mr2 w-25">Location: {selectedLeakPod["locationName"]}</H4>
                        </div>
                        </div>
                    )}
                    
                </div>
            )}

            {/* ================= */}
            {/* Assigned Leak Pods */}
            {/* ================= */}
            {assignedLeaks.map((leak, index) => (
                <div
                    key={index}
                    className="mb3 ba b--black-10 br3 pa3 bg-white shadow-4"
                >
                    <div className="flex items-start">
                        <div className="flex items-start mb2 w-90">
                        <H3 className="mr2 w-25">{leak["pillarId"]}</H3>
                        <H4 className="mr2 w-25">{leak["moduleId"]}</H4>
                        <H4 className="mr2 w-25">Activity: {leak["activity"]}</H4>
                        <H4 className="mr2 w-25">Status: {leak["status"]}</H4>
                        </div>
                    </div>
                    <div className="flex items-start">
                    <div className="flex items-start w-90">
                        <H4 className="mr2 w-25">Site: {leak["site"]}</H4>
                        <H4 className="mr2 w-25">Building: {leak["building"]}</H4>
                        <H4 className="mr2 w-25">Floor: {leak["floor"]}</H4>
                        <H4 className="mr2 w-25">Location: {leak["locationName"]}</H4>
                    </div>
                    <div className="flex items-center mb2 w-10">
                        {hasPermission(USER_PERMISSIONS.EDIT_FLOW_MONITOR) ? (
                            <button
                                type="button"
                                className="f7 link dim br2 ph3 pv2 dib white bg-dark-red bn"
                                onClick={() => unassignLeakPod(leak["pillarId"])}
                            >
                                Unassign
                            </button>
                            ) : ""}
                        </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
    )
}


AssignedLeakPods.propTypes = {
  flowPillarId: PropTypes.string.isRequired,
}

export default AssignedLeakPods
