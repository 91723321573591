import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../services/api'

import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  RadioButton,
  RadioGroup,
  Spin, } from '../common/Ant'
import { H2 } from '../common/Headers'
import { dateTimeFormatter } from '../../utils/date'
import { configurePagination } from '../../utils/helpers'
import { Anchor, ActionAnchor } from '../common/Anchor'


const LeakEvents = ({ flowPillarId }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [leakEvents, setLeakEvents] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const eventsPerPage = 10

    // ================
    // API Functions
    // ================
    const getLeakEvents = useCallback(() => {
        setIsLoading(true)

        api.getFlowMonitorLeakEvents(flowPillarId)
        .then(data => {
            setLeakEvents(data['leakEvents'])
            setIsLoading(false)
        })
        .catch(e => {
            console.log("Error getting leak events")
            console.log(e)
            setIsLoading(false)
        })
    }, [flowPillarId])

    useEffect(() => {
        getLeakEvents()
    }, [getLeakEvents])

    // const handleChange = (pagination) => {
    //   const {
    //     page,
    //     perPage,
    //     online,
    //     podType,
    //     batteryIndicator,
    //     status,
    //     firmwareVersion,
    //     productVersion,
    //     siteSlug,
    //     assetLocationId,
    //     searchText,
    //   } = this.state
  
    //   if (page !== pagination.current) {
    //     const query = {
    //       page: pagination.current,
    //       perPage,
    //       online,
    //       podType,
    //       batteryIndicator,
    //       status,
    //       firmwareVersion,
    //       productVersion,
    //       siteSlug,
    //       assetLocationId,
    //       filter: searchText,
    //     }
  
    //     const qs = createQueryString(query)
  
    //     getAllPods(qs)
  
    //     updateQueryParams && updateQueryParams({ search: qs })
    //   }
  
    //   this.setState({
    //     page: pagination.current,
    //     sortedInfo: sorter,
    //   })
    // }

    // ================
    // Render
    // ================
    const renderLeakEvents = () => {
      const indexOfLastEvent = currentPage * eventsPerPage
      const indexOfFirstEvent = indexOfLastEvent - eventsPerPage
      const currentEvents = leakEvents.slice(indexOfFirstEvent, indexOfLastEvent)

      const paginate = (pageNumber) => setCurrentPage(pageNumber)

      return (
        <div>
          <Table
            className="nested-table"
            dataSource={leakEvents}
            rowKey="id"
          >
            <Column
              title="Event Time"
              dataIndex="eventTime"
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="Pillar Id"
              dataIndex="leakPuck"
              render={text => (text ? 
                                <Anchor
                                  to={`/inventory/pods/${text['pillarId']}`}
                                  className="pl2"
                                >
                                  {text['pillarId']}
                                </Anchor>
                                : '--')
              }
            />
            <Column
              title="Module Id"
              dataIndex="leakPuck"
              render={text => (text ? text['moduleId'] : '--')}
            />
            <Column
              title="Site"
              dataIndex="site"
              render={text => (text ? 
                <Anchor
                  to={`/sites/${text['slug']}`}
                  className="pl2"
                >
                  {text['name']}
                </Anchor>
                : '--')
              }
            />
            <Column
              title="Location"
              dataIndex="location"
              render={text => (text ? 
                <Anchor
                  to={`${text['url']}`}
                  className="pl2"
                >
                  {text['name']}
                </Anchor>
                : '--')
              }
            />
            <Column
              title="Building"
              dataIndex="building"
              render={text => (text ? 
                <Anchor
                  to={`${text['url']}`}
                  className="pl2"
                >
                  {text['name']}
                </Anchor>
                : '--')
              }
            />
            <Column
              title="Floor"
              dataIndex="floor"
              render={text => (text ? 
                <Anchor
                  to={`${text['url']}`}
                  className="pl2"
                >
                  {text['name']}
                </Anchor>
                : '--')
              }
            />
            
          </Table>
        </div>
      )

      // return (
      //     <div>
      //       <table className="w-100 collapse ba br2 b--black-10 pv2 ph3">
      //         <thead>
      //           <tr className="striped--near-white">
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Event Time</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Pillar Id</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Module Id</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Site</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Location</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Building</th>
      //             <th className="pv2 ph3 tl f6 fw6 ttu">Floor</th>
      //           </tr>
      //         </thead>
      //         <tbody className="lh-copy">
      //           {currentEvents.map((leakEvent, index) => (
      //             <tr key={index} className="striped--near-white">
      //               <td className="pv2 ph3">{dateTimeFormatter(leakEvent['eventTime'])}</td>
      //               <td className="pv2 ph3">{leakEvent['leakPuck']['pillarId']}</td>
      //               <td className="pv2 ph3">{leakEvent['leakPuck']['moduleId']}</td>
      //               <td className="pv2 ph3">{leakEvent['site']}</td>
      //               <td className="pv2 ph3">{leakEvent['location']}</td>
      //               <td className="pv2 ph3">{leakEvent['building']}</td>
      //               <td className="pv2 ph3">{leakEvent['floor']}</td>
      //             </tr>
      //           ))}
      //         </tbody>
      //       </table>

      //       <div className="pagination">
      //         {Array.from({ length: Math.ceil(leakEvents.length / eventsPerPage) }, (_, i) => (
      //         <button key={i} onClick={() => paginate(i + 1)} className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}>
      //             {i + 1}
      //         </button>
      //         ))}
      //       </div>
      //     </div>
      // )
    }

    return isLoading ? (
            <Spin size="large" className="w-100 center mv5" />
        ) : (
            <div>
                {renderLeakEvents()}
            </div>
        )
}

LeakEvents.propTypes = {
  flowPillarId: PropTypes.string.isRequired,
}

export default LeakEvents;                