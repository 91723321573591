import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'
import TIMEZONES from 'google-timezones-json'
import US_STATES from 'datasets-us-states-abbr-names'
import Divider from '../common/Divider'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Checkbox from '../common/Checkbox'
import Legend from '../common/Legend'
import FormError from '../common/FormError'
import { ActionAnchor } from '../common/Anchor'
import { H4 } from '../common/Headers'
import { required, isNumeric } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { hasRole } from '../../utils/hasPermission'
import {
  SITE_STATUS,
  ROLES,
  MESSAGES,
  COMPANY_TYPES,
  SITE_INSTALL_TYPE,
  UNIT_PRESSURE,
  UNIT_TEMPERATURE,
  UNIT_AMBIENT_LIGHT,
} from '../../constants'

const Contact = ({ fields, label }) => (
  <div className="mb3">
    <H4>{label}</H4>
    {fields.map((contact, index) => {
      return (
        <div key={index}>
          {index > 0 ? <Divider /> : null}
          <H4 inline>Contact {index + 1}</H4>
          <ActionAnchor onClick={() => fields.remove(index)}>
            Remove
          </ActionAnchor>

          <div className="flex-ns">
            <Field
              name={`${contact}.name`}
              type="text"
              component={InputContainer}
              label="Name"
              className="w-third-ns w-100 mr3-ns"
            />
            <Field
              name={`${contact}.email`}
              type="text"
              component={InputContainer}
              label="Email"
              className="w-third-ns w-100 mr3-ns"
            />
            <Field
              name={`${contact}.phone`}
              type="text"
              component={InputContainer}
              label="Phone Number"
              className="w-third-ns w-100"
            />
          </div>
        </div>
      )
    })}
    <ActionAnchor onClick={() => fields.push()} button>
      Create New
    </ActionAnchor>
  </div>
)

Contact.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
}

const EditSiteForm = props => {
  const {
    handleSubmit,
    submitting,
    error,
    submitAction,
    companies,
    initialValues,
    formValues,
  } = props

  const submit = handleSubmit(submitAction)

  const { users } = initialValues

  return (
    <section className="EditSiteForm">
      <Form onSubmit={submit}>
        <Legend>Project Details</Legend>
        <div className="flex-ns">
          <Field
            name="name"
            type="text"
            component={InputContainer}
            label="Name"
            validate={[required]}
            className="w-100"
          />
        </div>
        <div className="flex-ns">
          <Field
            name="companyId"
            component={SelectContainer}
            label="Company"
            validate={[required]}
            placeholder="Select a company"
            className="w-50-ns mr3-ns w-100"
            disabled={initialValues && !!initialValues.slug}
            filterable
          >
            {companies.map(x => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Field>
          <Field
            name="insuranceCarrierSlug"
            component={SelectContainer}
            label="Insurance Carrier"
            placeholder="Select an insurer"
            className="w-50-ns w-100"
            filterable
          >
            {companies
              .filter(x => x.companyType === COMPANY_TYPES.INSURER)
              .map(x => (
                <Option value={x.slug} key={x.slug}>
                  {x.name}
                </Option>
              ))}
          </Field>
        </div>
        <div className="flex-ns">
          <Field
            name="description"
            type="text"
            component={InputContainer}
            label="Description"
            className="w-100"
          />
        </div>
        <div className="flex-ns">
          <Field
            name="status"
            component={SelectContainer}
            label="Status"
            validate={[required]}
            placeholder="Select a status"
            className="w-100 mr3-ns w-one-third-ns"
            onChange={(_, nextValue, prevValue) => {
              if (
                prevValue !== nextValue &&
                nextValue === SITE_STATUS.COMPLETED
              ) {
                global.alert(MESSAGES.CONFIRM_SITE_COMPLETION)
              }
            }}
            filterable
          >
            {Object.keys(SITE_STATUS).map(x => (
              <Option value={SITE_STATUS[x]} key={x}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Field>
          <Field
            name="siteInstallType"
            component={SelectContainer}
            label="Install Type"
            placeholder="Select a type"
            className="w-100 mr3-ns w-one-third-ns"
            filterable
          >
            {Object.keys(SITE_INSTALL_TYPE).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Field>
          <Field
            name="squareFootage"
            type="number"
            component={InputContainer}
            label="Square Footage"
            className="w-100 w-one-third-ns"
            validate={[isNumeric]}
          />
        </div>
        <Divider />
        <Legend>Project Address</Legend>
        <Field
          name="address"
          type="text"
          component={InputContainer}
          label="Address"
          validate={
            formValues.status !== SITE_STATUS.PROPOSED ? [required] : null
          }
        />
        <div className="flex-ns">
          <Field
            name="city"
            type="text"
            component={InputContainer}
            label="City"
            className="w-50-ns w-100 mr3-ns"
            validate={
              formValues.status !== SITE_STATUS.PROPOSED ? [required] : null
            }
          />
          <Field
            name="state"
            component={SelectContainer}
            label="State"
            className="w-25-ns w-100 mr3-ns"
            placeholder="Select a state"
            validate={
              formValues.status !== SITE_STATUS.PROPOSED ? [required] : null
            }
            filterable
          >
            {Object.keys(US_STATES).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(US_STATES[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="zipCode"
            type="string"
            component={InputContainer}
            label="Zip Code"
            className="w-25-ns w-100"
            validate={
              formValues.status !== SITE_STATUS.PROPOSED
                ? [required, isNumeric]
                : [isNumeric]
            }
          />
        </div>
        <Divider />
        <div className="flex-ns">
          <Field
            name="lat"
            type="number"
            component={InputContainer}
            label="Latitude"
            className="w-100 mr3-ns w-third-ns"
            validate={
              formValues.status !== SITE_STATUS.PROPOSED ? [required] : null
            }
          />
          <Field
            name="lng"
            type="number"
            component={InputContainer}
            label="Longitude"
            className="w-100 mr3-ns w-third-ns"
            validate={
              formValues.status !== SITE_STATUS.PROPOSED ? [required] : null
            }
          />
          <Field
            name="timezoneName"
            component={SelectContainer}
            label="Time Zone"
            placeholder="Select a time zone"
            className="w-100 w-third-ns"
            validate={[required]}
            filterable
          >
            {Object.keys(TIMEZONES).map(x => (
              <Option value={x} key={x}>
                {TIMEZONES[x]}
              </Option>
            ))}
          </Field>
        </div>
        <Divider />
        <Legend>Shipping Address</Legend>
        <Field
          name="shippingAddress"
          type="text"
          component={InputContainer}
          label="Address"
        />
        <div className="flex-ns">
          <Field
            name="shippingCity"
            type="text"
            component={InputContainer}
            label="City"
            className="w-50-ns w-100 mr3-ns"
          />
          <Field
            name="shippingState"
            component={SelectContainer}
            label="State"
            className="w-25-ns w-100 mr3-ns"
            placeholder="Select a state"
            filterable
          >
            {Object.keys(US_STATES).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(US_STATES[x])}
              </Option>
            ))}
          </Field>
          <Field
            name="shippingZipCode"
            type="string"
            component={InputContainer}
            label="Zip Code"
            className="w-25-ns w-100"
            validate={[isNumeric]}
          />
        </div>
        <Divider />
        <Legend>Contacts</Legend>
        <div className="bg-near-white pa2 mb3">
          <H4>Site Contacts</H4>
          <div className="flex-ns">
            <Field
              name="primaryContactUserId"
              component={SelectContainer}
              label="Primary Site Contact"
              className="w-50-ns w-100 mr3-ns"
              placeholder="Select a user"
              filterable
            >
              {users.map(user => (
                <Option value={user.id.toString()} key={user.id.toString()}>
                  {user.firstName} {user.lastName}
                </Option>
              ))}
            </Field>
            <Field
              name="secondaryContactUserId"
              component={SelectContainer}
              label="Secondary Site Contact"
              className="w-50-ns w-100"
              placeholder="Select a user"
              filterable
            >
              {users.map(user => (
                <Option value={user.id.toString()} key={user.id.toString()}>
                  {user.firstName} {user.lastName}
                </Option>
              ))}
            </Field>
          </div>
        </div>
        <div className="bg-near-white pa2 mb3">
          <div className="flex-ns">
            <FieldArray
              name="customerContactInfo"
              component={Contact}
              label="Customer Contacts"
            />
          </div>
        </div>
        <div className="bg-near-white pa2 mb3">
          <div className="flex-ns">
            <FieldArray
              name="shippingContactInfo"
              component={Contact}
              label="Shipping Contacts"
            />
          </div>
        </div>
        <div className="bg-near-white pa2 mb3">
          <div className="flex-ns">
            <FieldArray
              name="billingContactInfo"
              component={Contact}
              label="Billing Contacts"
            />
          </div>
        </div>
        {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
          <div>
            <Divider />
            <Legend>Project Dates</Legend>
            <div className="flex-ns">
              <Field
                name="deploymentDate"
                type="date"
                component={DateSelectorContainer}
                label="Installation Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                validate={[required]}
                className="w-100 mr3-ns w-50-ns"
                disableTime
              />
              <Field
                name="completionDate"
                type="date"
                component={DateSelectorContainer}
                label="Completion Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 w-50-ns"
                disableTime
              />
            </div>
            <div className="flex-ns">
              <Field
                name="contractStartDate"
                type="date"
                component={DateSelectorContainer}
                label="Approx. Contract Start Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 mr3-ns w-50-ns"
                disableTime
              />
              <Field
                name="contractEndDate"
                type="date"
                component={DateSelectorContainer}
                label="Approx. Contract End Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 w-50-ns"
                disableTime
              />
            </div>
            <div className="flex-ns">
              <Field
                name="sowSignatureDate"
                type="date"
                component={DateSelectorContainer}
                label="SOW Signature Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 mr3-ns w-50-ns"
                disableTime
              />
              <Field
                name="trainingCompleteDate"
                type="date"
                component={DateSelectorContainer}
                label="Training Complete Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 w-50-ns"
                disableTime
              />
            </div>
            <div className="flex-ns">
              <Field
                name="equipmentReturnedDate"
                type="date"
                component={DateSelectorContainer}
                label="Equipment Returned Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 mr3-ns w-50-ns"
                disableTime
              />
              <Field
                name="debriefCompleteDate"
                type="date"
                component={DateSelectorContainer}
                label="Debrief Complete Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                className="w-100 w-50-ns"
                disableTime
              />
            </div>
            <Divider />

            {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
              <>
                <Legend>Admin Configurations</Legend>
                <div className="flex-ns">
                  <Field
                    name="alertLagMinutes"
                    type="number"
                    component={InputContainer}
                    label="Alert Lag Minutes"
                    className="w-100 w-50-ns mr3-ns"
                    validate={[required]}
                  />
                  <Field
                    name="alertLeadMinutes"
                    type="number"
                    component={InputContainer}
                    label="Alert Lead Minutes"
                    className="w-100 mr3-ns w-50-ns"
                    validate={[required]}
                  />
                </div>
                <div className="flex-ns">
                  <Field
                    name="enableActionReport"
                    component={Checkbox}
                    type="checkbox"
                    label="Enable Action Reporting"
                    className="w-third-ns w-100 mr3-ns"
                  />
                  <Field
                    name="test"
                    component={Checkbox}
                    type="checkbox"
                    label="Test Site?"
                    className="w-25-ns w-100"
                  />
                </div>
                <Divider />
                <Legend>Smart Pod Sensors</Legend>
                <div className="flex-ns">
                  <Field
                    name="tempEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Temperature"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="humEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Humidity"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="presEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Pressure"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="dustEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Dust"
                    className="w-25-ns w-100"
                  />
                </div>
                <div className="flex-ns">
                  <Field
                    name="vocEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="VOC (Alpha)"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="soundEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Sound"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="ambientLightEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Ambient Light"
                    className="w-25-ns w-100 mr3-ns"
                  />
                  <Field
                    name="coEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Carbon Monoxide"
                    className="w-25-ns w-100"
                  />
                </div>
                <div className="flex-ns">
                  <Field
                    name="dewPointEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Dew Point"
                    className="w-25-ns w-100"
                  />
                  <Field
                    name="signalInfoEnabled"
                    component={Checkbox}
                    type="checkbox"
                    label="Signal"
                    className="w-25-ns w-100"
                  />
                </div>
                <Divider />

                <Legend>Units</Legend>
                <div className="flex-ns">
                  <Field
                    name="unitTemperature"
                    component={SelectContainer}
                    label="Temperature"
                    validate={[required]}
                    placeholder="Select a unit of temperature"
                    className="w-third-ns mr3-ns w-100"
                    filterable
                  >
                    <Option
                      value={UNIT_TEMPERATURE.CELSIUS}
                      key={UNIT_TEMPERATURE.CELSIUS}
                    >
                      {toTitleCase(UNIT_TEMPERATURE.CELSIUS)}
                    </Option>
                    <Option
                      value={UNIT_TEMPERATURE.FAHRENHEIT}
                      key={UNIT_TEMPERATURE.FAHRENHEIT}
                    >
                      {toTitleCase(UNIT_TEMPERATURE.FAHRENHEIT)}
                    </Option>
                    ))
                  </Field>

                  <Field
                    name="unitAmbientLight"
                    component={SelectContainer}
                    label="Ambient Light"
                    validate={[required]}
                    placeholder="Select a unit of ambient light"
                    className="w-third-ns mr3-ns w-100"
                    filterable
                  >
                    <Option
                      value={UNIT_AMBIENT_LIGHT.FOOT_CANDLES}
                      key={UNIT_AMBIENT_LIGHT.FOOT_CANDLES}
                    >
                      {toTitleCase(UNIT_AMBIENT_LIGHT.FOOT_CANDLES)}
                    </Option>
                    <Option
                      value={UNIT_AMBIENT_LIGHT.MICROWATT_PER_CM_SQ}
                      key={UNIT_AMBIENT_LIGHT.MICROWATT_PER_CM_SQ}
                    >
                      {toTitleCase(UNIT_AMBIENT_LIGHT.MICROWATT_PER_CM_SQ)}
                    </Option>
                    ))
                  </Field>

                  <Field
                    name="unitPressure"
                    component={SelectContainer}
                    label="Pressure"
                    validate={[required]}
                    placeholder="Select a unit of pressure"
                    className="w-third-ns w-100"
                    filterable
                  >
                    <Option
                      value={UNIT_PRESSURE.KILOPASCAL}
                      key={UNIT_PRESSURE.KILOPASCAL}
                    >
                      {toTitleCase(UNIT_PRESSURE.KILOPASCAL)}
                    </Option>
                    <Option
                      value={UNIT_PRESSURE.INCHES_OF_MERCURY}
                      key={UNIT_PRESSURE.INCHES_OF_MERCURY}
                    >
                      {toTitleCase(UNIT_PRESSURE.INCHES_OF_MERCURY)}
                    </Option>
                    ))
                  </Field>
                </div>
                <Divider />
                <Legend>Third-Party Integrations</Legend>
                <div className="flex-ns">
                  <Field
                    name="kastleBuildingId"
                    type="text"
                    component={InputContainer}
                    label="Kastle Building ID"
                    className="w-100 mr3-ns w-50-ns"
                  />
                </div>
              </>
            )}
          </div>
        )}
        <Divider />
        <Legend>Notification Preferences</Legend>
        <div className="flex-ns">
          <Field
            name="notificationMuted"
            component={Checkbox}
            type="checkbox"
            label="Mute Alert Notifications"
            className="w-third-ns w-100 mr3-ns"
          />
          <Field
            name="systemNotificationMuted"
            component={Checkbox}
            type="checkbox"
            label="Mute Offline Notifications"
            className="w-third-ns w-100"
          />
        </div>
        <Divider />
        <FormError error={error} />
        <div className="flex justify-between">
          <CancelButton defaultLocation="/sites" />
          <Button text="Submit" type="submit" submitting={submitting} />
        </div>
      </Form>
    </section>
  )
}

EditSiteForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  networks: PropTypes.arrayOf(PropTypes.object).isRequired,
}

EditSiteForm.defaultProps = { formValues: {} }

export default reduxForm({ form: 'EditSiteForm' })(EditSiteForm)
