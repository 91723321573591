import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import TinySensorChartContainer from '../containers/TinySensorChartContainer'
import { Anchor, ActionAnchor } from './common/Anchor'
import { Tooltip, Icon } from './common/Ant'
import { H4 } from './common/Headers'
import Divider from './common/Divider'
import { Bread, Crumb } from './common/Bread'
import { dateTimeFormatter } from '../utils/date'
import { existy } from '../utils/helpers'
import { hasPermission } from '../utils/hasPermission'
import { UNITS, ALERT_TYPES, USER_PERMISSIONS } from '../constants'
import iconFlowMonitor from '../assets/flow-dark.svg'
import iconHumidity from '../assets/icon-humidity-dark.svg'
import iconDust from '../assets/icon-particulates-dark.svg'
import iconPressure from '../assets/icon-pressure-dark.svg'
import iconSignal from '../assets/icon-signal-dark.svg'
import iconTemperature from '../assets/icon-temperature-dark.svg'
import iconCarbonMonoxide from '../assets/icon-carbon.svg'
import FlowMonitorSidebar from './flow_monitor/FlowMonitorSidebar'

class FloorPlanSidebar extends Component {
  static propTypes = {
    selectedItem: PropTypes.object.isRequired,
    snapshotData: PropTypes.object.isRequired,
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getLocationSnapshot: PropTypes.func.isRequired,
    handleCloseSidebar: PropTypes.func.isRequired,
    deleteMapLocation: PropTypes.func,
    deleteGatewayLocation: PropTypes.func,
    handleFloorPlanModalCancel: PropTypes.func,
  }

  static defaultProps = {
    snapshotData: {},
  }

  componentDidMount() {
    const {
      siteSlug,
      floorId,
      getLocationSnapshot,
      selectedItem: { id, gatewayPillarId },
    } = this.props

    if (!gatewayPillarId) {
      getLocationSnapshot(siteSlug, floorId, id)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      siteSlug,
      floorId,
      getLocationSnapshot,
      selectedItem: { id, gatewayPillarId },
    } = this.props

    if (!gatewayPillarId && prevProps.selectedItem.id !== id) {
      getLocationSnapshot(siteSlug, floorId, id)
    }
  }

  chartContainerEl = null

  renderSensorData = ({
    time,
    temp,
    hum,
    dust,
    pres,
    carbonMonoxide,
    signalRssi,
    flowValue,
    // sound,
    // voc,
    // signalSf,
    // signalSnr,
  }) => (
    <div className="mb3">
      <div className="b mb3 tc">{dateTimeFormatter(time, true)}</div>
      <ul className="pl0 mb0 list">
        {existy(flowValue) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img
                src={iconFlowMonitor}
                alt="flow-value"
                className="w1 h1 mr2"
              />
              <span className="b">Water Flow</span>
            </div>
            {parseFloat(flowValue).toFixed(2)} {UNITS.FLOW_VALUE}
          </li>
        )}
        {existy(flowValue) && existy(temp) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img
                src={iconTemperature}
                alt="Temperature"
                className="w1 h1 mr2"
              />
              <span className="b">Pipe Temperature</span>
            </div>
            {parseFloat(temp).toFixed(2)} {UNITS.TEMPERATURE}
          </li>
        )}
        {!existy(flowValue) && existy(temp) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img
                src={iconTemperature}
                alt="Temperature"
                className="w1 h1 mr2"
              />
              <span className="b">Temperature</span>
            </div>
            {parseFloat(temp).toFixed(2)} {UNITS.TEMPERATURE}
          </li>
        )}
        {!existy(flowValue) && existy(hum) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img src={iconHumidity} alt="Humidity" className="w1 h1 mr2" />
              <span className="b">Humidity</span>
            </div>
            {parseFloat(hum).toFixed(2)} {UNITS.HUMIDITY}
          </li>
        )}
        {existy(dust) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img src={iconDust} alt="Dust" className="w1 h1 mr2" />
              <span className="b">Dust</span>
            </div>
            {parseFloat(dust).toFixed(2)} {UNITS.DUST}
          </li>
        )}
        {existy(carbonMonoxide) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img
                src={iconCarbonMonoxide}
                alt="Carbon Monoxide"
                className="w1 h1 mr2"
              />
              <span className="b">Carbon Monoxide</span>
            </div>
            {parseFloat(carbonMonoxide).toFixed(2)} {UNITS.CARBON_MONOXIDE}
          </li>
        )}
        {existy(pres) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img src={iconPressure} alt="Pressure" className="w1 h1 mr2" />
              <span className="b">Pressure</span>
            </div>
            {parseFloat(pres).toFixed(2)} {UNITS.PRESSURE}
          </li>
        )}
        {existy(signalRssi) && (
          <li className="flex justify-between mb1">
            <div className="flex items-center">
              <img src={iconSignal} alt="Pressure" className="w1 h1 mr2" />
              <span className="b">Signal Strength</span>
            </div>
            {parseFloat(signalRssi).toFixed(2)} {UNITS.SIGNAL_RSSI}
          </li>
        )}
      </ul>
    </div>
  )

  renderCloseButton = () => (
    <div
      onClick={this.props.handleCloseSidebar}
      className="pointer absolute top-0 right-0 pa2"
    >
      <Icon type="close" />
    </div>
  )

  render() {
    const {
      selectedItem,
      snapshotData,
      siteSlug,
      floorId,
      deleteMapLocation,
      deleteGatewayLocation,
      handleFloorPlanModalCancel,
    } = this.props

    console.log("snapshotData", snapshotData)

    const componentClassNames =
      'FloorPlanSidebar absolute bg-near-white z-6 h-100 ba b--gray br2 br--left shadow-5 ph3 pv4 overflow-y-auto'

    if (selectedItem.gatewayPillarId) {
      const {
        gatewayName,
        description,
        gatewayId,
        gatewayPillarId,
        online,
      } = selectedItem

      return (
        <div className={componentClassNames}>
          {this.renderCloseButton()}
          <div
            className={cx('mb2 br2 ba b--gray', {
              'bg-washed-green': online,
              'bg-washed-red': !online,
            })}
          >
            <Bread>
              <Crumb>
                {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) ? (
                  <ActionAnchor onClick={handleFloorPlanModalCancel}>
                    <Anchor to={`/inventory/gateways/${gatewayPillarId}`}>
                      {gatewayName}
                    </Anchor>
                  </ActionAnchor>
                ) : (
                  gatewayName
                )}
                {online ? (
                  <Tooltip title="Online">
                    <Icon type="check-circle-o" className="pl1 green f4" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Offline">
                    <Icon type="poweroff" className="pl1 red f4" />
                  </Tooltip>
                )}
              </Crumb>
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.EDIT_GATEWAY) && (
            <div className="flex justify-center mb3">
              <span>
                <ActionAnchor onClick={handleFloorPlanModalCancel}>
                  <Anchor to={`/inventory/gateways/${gatewayPillarId}/edit`}>
                    Edit
                  </Anchor>
                </ActionAnchor>
                <Divider vertical />
              </span>
              <ActionAnchor onClick={() => deleteGatewayLocation(gatewayId)}>
                Remove
              </ActionAnchor>
            </div>
          )}
          <Divider />
          {description && (
            <div className="mb3">
              <p className="tc">{description}</p>
            </div>
          )}
        </div>
      )
    } else {
      const {
        id,
        name,
        description,
        podPillarId,
        flowMonitorPillarId,
        flowMonitorId,
        alertActive,
        alertType,
        alertStartDate,
        alertEndDate,
        activeAlerts,
      } = selectedItem

      const mostRecentAlert =
        activeAlerts.length > 0 ? activeAlerts[activeAlerts.length - 1] : {}

      return (
        <div className={componentClassNames}>
          {this.renderCloseButton()}
          <div
            className={cx('mb2 br2 ba b--gray', {
              'bg-washed-green':
                !alertActive ||
                (alertActive && alertType !== ALERT_TYPES.THRESHOLD),
              'bg-washed-red': alertActive,
            })}
          >
            <Bread>
              <Crumb>
                <ActionAnchor onClick={handleFloorPlanModalCancel}>
                  <Anchor
                    to={
                      flowMonitorPillarId
                        ? `/sites/${siteSlug}/floors/${floorId}/locations/${id}/flow-monitor-chart`
                        : `/sites/${siteSlug}/floors/${floorId}/locations/${id}`
                    }
                  >
                    {name}
                  </Anchor>
                </ActionAnchor>
              </Crumb>
              {podPillarId && (
                <Crumb>
                  <span>
                    {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                      <span>
                        <ActionAnchor onClick={handleFloorPlanModalCancel}>
                          <Anchor to={`/inventory/pods/${podPillarId}`}>
                            {podPillarId}
                          </Anchor>
                        </ActionAnchor>
                      </span>
                    ) : (
                      <span>({podPillarId})</span>
                    )}
                    {alertActive && alertType !== ALERT_TYPES.THRESHOLD ? (
                      <Tooltip title="Offline">
                        <Icon type="poweroff" className="pl1 red f4" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Online">
                        <Icon type="check-circle-o" className="pl1 green f4" />
                      </Tooltip>
                    )}
                  </span>
                </Crumb>
              )}
              {flowMonitorPillarId && (
                <Crumb>
                  <FlowMonitorSidebar
                    flowId={flowMonitorId}
                    flowPillarId={flowMonitorPillarId}
                    handleCancel={handleFloorPlanModalCancel}
                    alertActive={alertActive}
                  />
                </Crumb>
              )}
            </Bread>
          </div>
          {hasPermission(USER_PERMISSIONS.EDIT_LOCATION) && (
            <div className="flex justify-center mb3">
              <span>
                <ActionAnchor onClick={handleFloorPlanModalCancel}>
                  <Anchor
                    to={`/sites/${siteSlug}/floors/${floorId}/locations/${id}/edit`}
                  >
                    Edit
                  </Anchor>
                </ActionAnchor>
                <Divider vertical />
              </span>
              <ActionAnchor
                onClick={() => deleteMapLocation({ siteSlug, floorId, id })}
              >
                Delete
              </ActionAnchor>
            </div>
          )}
          {description && (
            <div className="mb3">
              <Divider />
              <p className="tc">{description}</p>
            </div>
          )}
          {alertActive && (
            <div>
              <Divider />
              <div className="mb2">
                <H4 className="tc">Active Alert</H4>
                <div className="flex justify-between mb1">
                  <div className="b">Name</div>
                  <div>
                    {alertType === ALERT_TYPES.THRESHOLD
                      ? mostRecentAlert.thresholdName
                      : flowMonitorPillarId
                      ? 'Water Monitor Offline'
                      : 'Smart Pod Offline'}
                  </div>
                </div>
                {alertStartDate && (
                  <div className="flex justify-between mb1">
                    <div className="b">Start Time</div>
                    <div>{dateTimeFormatter(alertStartDate, true)}</div>
                  </div>
                )}
                {alertEndDate && (
                  <div className="flex justify-between mb1">
                    <div className="b">End Time</div>
                    <div>{dateTimeFormatter(alertEndDate, true)}</div>
                  </div>
                )}
                {flowMonitorPillarId ? (
                  <div className="tc">
                    <ActionAnchor onClick={handleFloorPlanModalCancel}>
                      <Anchor
                        to={`/alerts/flow-monitors/${mostRecentAlert.slug}`}
                      >
                        View Alert
                      </Anchor>
                    </ActionAnchor>
                  </div>
                ) : (
                  <div className="tc">
                    <ActionAnchor onClick={handleFloorPlanModalCancel}>
                      <Anchor to={`/alerts/pods/${mostRecentAlert.slug}`}>
                        View Alert
                      </Anchor>
                    </ActionAnchor>
                  </div>
                )}
              </div>
            </div>
          )}
          <Divider />
          <div>
            <H4 className="tc">Latest Reading</H4>
            {snapshotData.payload && snapshotData.payload.time ? (
              this.renderSensorData(snapshotData.payload)
            ) : (
              <p className="tc">No reading available.</p>
            )}
          </div>
          <Divider />
          <div ref={el => (this.chartContainerEl = el)}>
            <H4 className="tc">Last 24 Hours</H4>
            <TinySensorChartContainer
              siteSlug={siteSlug}
              floorId={floorId}
              locationId={id}
              sidebarWidth={
                this.chartContainerEl ? this.chartContainerEl.offsetWidth : 0
              }
            />
          </div>
        </div>
      )
    }
  }
}

export default FloorPlanSidebar
